<template>
  <div class="privacy-policy">
    <div class="container-s container-privacy-policy">
      <div class="col-privacy">
        <div class="row-privacy text-center">
          <h2>{{ cms.page.fgPrivacyPolicy }}</h2>
        </div>
        <div class="row-privacy">
          <p v-html="cms.page.fgIntroParagraph"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";

export default {
  name: "PrivacyPolicy",
  components: {},
  data() {
    return {
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "privacy_policy",
          MinistryUpdates: false
        },
        page: {},
      },
    };
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  mounted() {
    this.setTitlePageFromCms()
  },
  async created() {
    this.setContentLoaded(false);
    await this.fetchCmsData();
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setTitlePageFromCms(results)
              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgPrivacyPolicy ? page.fgPrivacyPolicy : 'Privacy Policy'
      const pageSubtitle = page && page.fgWeCareAboutYourPrivacy ? page.fgWeCareAboutYourPrivacy : 'We care about your privacy'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "hero-default-desktop",
        btn_history: false,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.privacy-policy {
  display: flex;
  justify-content: center;

  .container-s {
    padding: 46px 115px 120px;

    @media screen and (max-width: $md) {
      padding: 25px 15px 50px;
    }
    @media screen and (max-width: $xxl) {
      padding: 0;
    }
    @media screen and (min-width: $sm) {
      padding: 25px 25px 50px;
    }
  }

  @media screen and (max-width: $sm) {
    .container-privacy-policy {
      max-width: 100%
    }
  }


  .text-center {
    text-align: center;
  }

  .col-privacy {
    max-width: 750px;
    font-size: 18px;
    line-height: 1.5;
    max-width: 100%;
    @media screen and (min-width: $md) {
      width: 100%;
    }
    @media screen and (max-width: $md) {
      width: 100%;
    }

    h2 {
      color: #0c2e3c;
      margin: 20px 0 10px;
    }

    :deep p {
      font-size: 16px;
      line-height: 1.5;
      color: #0c2e3c;
      padding: 0 15px 0 0;
      @media screen and (max-width: $md) {
        padding: 25px 0;
      }
    }
  }

  .row-privacy {
    width: 100%;
    max-width: 750px;
  }

  @media screen and (max-width: $sm) {
    .row-privacy {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

:deep .page-content__section {
  margin-bottom: 4rem;
}

:deep .page-content__section-head {
  font-size: 2rem;
  font-weight: 700;
}

:deep ul {
  list-style-type: disc;
}

:deep dl, :deep ol, :deep ul {
  color: #1b2832;
  list-style-position: inside;
  line-height: 1.7em;
}

:deep dl li, :deep ol li, :deep ul li {
  margin-bottom: .2rem;
}

:deep dfn, :deep em, :deep i, :deep var {
  font-style: italic;
}

:deep a {
  text-decoration: none;
}

:deep h2 {
  margin: 10px 0 10px;
}
</style>
